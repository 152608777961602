/*=| スケジュール(カレンダー)機能で使用するデータの授受 |=*/

import { s3Request } from '../datasource/sangaApi';
import { FootballCalendarModel, CalendarScheduleModel } from '../entity/modelSchedule';
import { jpHolidays as jpHolidaysApi } from '../datasource/jpHolidayApi'
// --

// スケジュール(カレンダー)情報のJSONファイル取得リクエスト
export async function requesGetScheduleJson() {
  return await s3Request().then(async (res) => {
    var statusCode = res.status;
    return await res.json().then((body: JSON) => {
      var models = statusCode != 200 ? [] : scheduleJsonParser(body);
      const responseModel: FootballCalendarModel = { code: statusCode, models: models }
      return responseModel;
    });
  });
}

// JSONのパーサー: football_calendar.json
function scheduleJsonParser(json: JSON) {
  const jsonArray: Array<JSON> = JSON.parse(JSON.stringify(json));
  const models: Array<CalendarScheduleModel> = [];
  jsonArray.forEach((json) => {
    const parsed = JSON.parse(JSON.stringify(json));
    var eventList = [];

    // map や forEach だとエラーになるのでforで回す
    for (const event of parsed.event) {
      eventList.push({
        id: event.id,
        type: event.type,
        sortKey: event.sort_key,
        haSection: event.HA_section,
        opponent: event.opponent,
        startTime: event.start_time,
        text: event.text,
        backgroundColor: event.background_color,
        linkUrl: event.link_url,
        imagePath: event.image_path
      })
    }
    var model: CalendarScheduleModel = {
      year: parsed.year,
      month: parsed.month,
      date: parsed.date,
      event: eventList
    }
    models.push(model);
  });

  return models;
}

// 休日情報の取得
export async function jpHolidays() {
  return jpHolidaysApi().then((holidays) => {
    return holidays
    .map((it)=>toDate(it) ?? new Date(1980,1,1))
  })
}

// 文字列を日付に変更
function toDate(data:string):Date | null{
  try {
    return new Date(data)
  } catch (error) {
    // 変換できない場合は諦める
    return null
  }
}