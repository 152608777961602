//
import { isGameEvent, isHomegame } from '../models/modelValues'
import { MonthlyScheduleDatas, DaylyScheduleDatas, ScheduleData } from '../models/calendarData'
import { CalendarScheduleModel } from '../../data/entity/modelSchedule'
import { requesGetScheduleJson } from '../../data/repository/requestSchedule'
import { jpHolidays } from '../../data/repository/requestSchedule'
// 
import { } from '../models/calendarData'
//--

// カレンダーデータの取得
export async function getCalendarData() {
  return await requesGetScheduleJson().then((model) => {
    // サーバーエラーの場合はNullを返す
    switch (model.code) {
      // case 200: return  model.models.map((model)=> makeScheduleData(model));
      case 200: return makeMonthlyScheduleDatList(model.models);
      default: return null;
    }
  }).catch(error => {
    console.error("アクセスエラー: %s", error)
    return null;
  });
}

function makeMonthlyScheduleDatList(models: Array<CalendarScheduleModel>): Array<MonthlyScheduleDatas> {
  // models:日次だけまとめられたスケジュール[”YYYY/MM/DDに開催される全イベント”のリスト]

  // models を DaylyScheduleDatas に変える
  var daylyScheduleList: Array<DaylyScheduleDatas> = models.map(model => {
    var data: DaylyScheduleDatas = {
      year: Number(model.year),
      month: Number(model.month),
      day: Number(model.date),
      events: []
    }
    //
    model.event.forEach((event) => {
      var eventData: ScheduleData = {
        scheduleDate: new Date(model.year + "/" + model.month + "/" + model.date),
        eventId: event.id,
        eventSortKey: event.sortKey,
        eventType: event.type,
        eventInfo: event.text,
        eventIconPath: event.imagePath,
        eventUrl: event.linkUrl,
        gameInfo: null,
        ticketBgColor: event.backgroundColor
      }
      if (isGameEvent(event.type)) {
        eventData.gameInfo = {
          isHomegame: isHomegame(event.haSection),
          opponentName: event.opponent,
          startTime: event.startTime
        }
      }
      // 
      data.events.push(eventData);
    });
    return data;
  });

  // DaylyScheduleDatas を、年月別に分ける
  var dateIndexLabels: Array<string> = [];//リストの見出し用文字列: [YYYY/MM] 
  var monthlyScheduleList: Array<MonthlyScheduleDatas> = [];

  daylyScheduleList.forEach((dayly) => {
    var indexLabel = dayly.year + "/" + dayly.month;
    var index = dateIndexLabels.indexOf(indexLabel);

    //見つからない場合は見出しいれて、スケジュールのデータを追加
    if (index === -1) {
      dateIndexLabels.push(indexLabel);
      var schedule: MonthlyScheduleDatas = {
        year: dayly.year,
        month: dayly.month,
        schedules: []
      }
      monthlyScheduleList.push(schedule);
      // 追加したのでインデックス更新(新規追加なので最後尾にあるはず)
      index = dateIndexLabels.length - 1;
    }
    // 対象年月のスケジュールリストに追加
    var monthly = monthlyScheduleList[index];
    monthly.schedules.push(dayly);
  });
  return monthlyScheduleList;
}

// 国民の休日ファイルを読み込んで、Date配列を返却する
export async function getJpHolidays() {
  return jpHolidays().catch((reason) => {
    return []
  })
}