import { JpHolidays } from "../value/values"

export async function jpHolidays() {
  return new Promise<Array<string>>((resolve, reject) => {
    resolve(readCsv())
  })
}

function readCsv(): Array<string> {
  /* 本来はCSVから読み込んで処理するのが理想だが、ローカルテキストファイルの
   * fsモジュールはNode.jsのモジュールであり、Reactとしてビルドするのが複雑な手順が必要なため
   * 直接日付をvaluesに持つことで対応する。
   */
  return JpHolidays()
}