/*=| カレンダー部分 |=*/
import { MonthlyScheduleDatas, ScheduleData, DaylyScheduleDatas } from '../../domain/models/calendarData';
import { useState } from 'react';
import ImgHome from '../../resources/HOME.png';
import ImgAway from "../../resources/AWAY.png";

import './styles/calendar.css'
// ==
export function Calendar(CalendarProp: {
  scheludes: Array<MonthlyScheduleDatas>, targetDate: Date,
  setDaylySchedules: any, autoScrollDataList: any, holidays: Date[]
}) {
  const [selectedDateCell, setSelectedDateCell] = useState<number>(firstSelectCell());// 選択日※初期値は現在の日付
  const [targetMonth, settargetMonth] = useState<number>(CalendarProp.targetDate.getMonth()); //表示中の月

  // 当該月のデータ取得
  var allSchedules = CalendarProp.scheludes.find(schedule => {
    return (CalendarProp.targetDate.getFullYear() === schedule.year) && (CalendarProp.targetDate.getMonth() + 1 === schedule.month);
  }) || { year: CalendarProp.targetDate.getFullYear(), month: CalendarProp.targetDate.getMonth(), schedules: [] };

  //月が変えられた時のフレーム削除処理
  if (targetMonth != CalendarProp.targetDate.getMonth()) {
    // 当該月の１日を選択
    var targetDate = CalendarProp.targetDate;
    targetDate.setDate(1);
    setSelectedDateCell(notargetFirstSelectCell(targetDate));
    CalendarProp.setDaylySchedules(getEventsDataFromScheudles(allSchedules.schedules, targetDate))

    // 表示月更新
    settargetMonth(CalendarProp.targetDate.getMonth());
  }

  // 
  return (
    <div id='calendar' className='body'>
      <CalendarCell targetDate={CalendarProp.targetDate} monthlySchedules={allSchedules} selectedDateCell={selectedDateCell}
        setDaylySchedules={CalendarProp.setDaylySchedules} setSelectedDateCell={setSelectedDateCell}
        key={"calendara-cell"} autoScrollDataList={CalendarProp.autoScrollDataList} holidays={CalendarProp.holidays} />
    </div>
  );
}

// 初期選択状態にするセルの位置を探す
function firstSelectCell() {
  // 現在の日付をとる 
  var date = new Date();
  var day = date.getDate();

  // 先頭の位置をとる
  date.setDate(1);
  var startDayOfWeek = (date.getDay() > 0) ? date.getDay() - 1 : 6;
  return day + startDayOfWeek - 1;//0スタートなので1減らす
}
// 該当月の先頭セルの位置を探す
function notargetFirstSelectCell(targetDate: Date) {
  // 先頭の位置をとる
  targetDate.setDate(1);
  var startDayOfWeek = (targetDate.getDay() > 0) ? targetDate.getDay() - 1 : 6;
  return startDayOfWeek;
}
// 与えられたのスケジュール情報から、指定日のイベントデータを抽出する。
function getEventsDataFromScheudles(schedules: Array<DaylyScheduleDatas>, targetDate: Date): Array<ScheduleData> {
  return schedules.find(events => {
    return events.day === targetDate.getDate() //1日のデータを取得
  })?.events || [];
}
// --
// = 曜日表示のヘッダー
export const CalendaraHeader = () => {
  var cellArray: Array<JSX.Element> = []
  cellArray.push(<div id='header' className='cell' key={"mon"}>MON</div>);
  cellArray.push(<div id='header' className='cell' key={"tue"}>TUE</div>);
  cellArray.push(<div id='header' className='cell' key={"wed"}>WED</div>);
  cellArray.push(<div id='header' className='cell' key={"thu"}>THU</div>);
  cellArray.push(<div id='header' className='cell' key={"fri"}>FRI</div>);
  cellArray.push(<div id='header' className='cell saturday' key={"sat"}>SAT</div>);
  cellArray.push(<div id='header' className='cell sunday' key={"sun"}>SUN</div>);
  return (
    <div id='calendar' className='header' key={"calendar-header"}>
      {cellArray}
    </div>
  );
}

// = カレンダーの日部分
const CalendarCell = (CalendarCellProp: {
  targetDate: Date, monthlySchedules: MonthlyScheduleDatas,
  selectedDateCell: number, setDaylySchedules: any, setSelectedDateCell: any,
  autoScrollDataList: any, holidays: Date[]
}) => {
  var daylyEventSetter = CalendarCellProp.setDaylySchedules;
  var selectedDayCellSetter = CalendarCellProp.setSelectedDateCell
  var target = CalendarCellProp.targetDate;
  var monthlySchedules = CalendarCellProp.monthlySchedules;
  // 
  var date = new Date(target.getFullYear(), target.getMonth());
  var calendarLayout: Array<JSX.Element> = [];
  // 最大6週
  for (let week: number = 0; week < 6; week++) {
    var dateOfWeek: Array<[number, boolean]> = [];
    //月曜開始なので 1開始
    for (let days: number = 1; days < 8; days++) {
      var pushDay = 0;
      switch (week) {
        case 0: //第1週目
          var weekDay = (days < 7) ? days : 0; // 7番目は日曜なので 0 と入れ替え
          pushDay = (date.getDay() === weekDay) ? date.getDate() : 0;
          break;
        default://2週目以降
          pushDay = (date.getDate() != 1) ? date.getDate() : 0;
          break;
      }
      // 休日を判定
      const sameDate = ((base: Date, to: Date) => ((base.getFullYear() === to.getFullYear()) && (base.getMonth() === to.getMonth()) && (base.getDate() === to.getDate())))
      const isHoliday = date.getDay() == 0 || CalendarCellProp.holidays.some((it) => sameDate(date, it));
      dateOfWeek.push([pushDay, isHoliday]);
      if (pushDay != 0) date.setDate(date.getDate() + 1);
    }

    var cellArray: Array<JSX.Element> = dateOfWeek.map(([date, isHoliday], idx) => {
      var daylyEvent = monthlySchedules.schedules.find(events => {
        return events.day === date
      }) || { year: target.getFullYear(), month: target.getMonth() + 1, day: date, events: [] };
      return <CalendarDayCell cellIdx={calendarLayout.length * 7 + idx} day={date} isSunday={idx >= 6} events={daylyEvent.events} selectedDateCell={CalendarCellProp.selectedDateCell}
        daylyEventSetter={daylyEventSetter} setSelectedDateCell={selectedDayCellSetter} key={"cell-" + (calendarLayout.length * 7 + idx)}
        autoScrollDataList={CalendarCellProp.autoScrollDataList} isHoliday={isHoliday} />;
    });

    calendarLayout.push(
      <div id='calendar' className='week-line' key={"week-line" + calendarLayout.length}>
        {cellArray}
      </div>
    );
  }
  return (
    <div id='callendar-cells'>
      {calendarLayout}
    </div>
  );
}

// カレンダーのマス生成
function CalendarDayCell(DayCellProp: {
  cellIdx: number, day: number, isSunday: boolean, events: Array<ScheduleData>, selectedDateCell: number,
  daylyEventSetter: any, setSelectedDateCell: any, autoScrollDataList: any, isHoliday: boolean
}) {
  var className = DayCellProp.isSunday ? 'body sunday' : 'body';
  var events = DayCellProp.events; //その日に開催されるイベント一式
  var day = DayCellProp.day;
  // 
  let eventMain: ScheduleData | null = events.find(event => event.eventType === 'game') ?? events.find(event => event.eventType === 'birthday') ?? null;
  var eventTickets: Array<ScheduleData> = events.filter(event => event.eventType === 'ticket');
  var eventOther: Array<ScheduleData> = events.filter(event => event.eventType != 'game' && event.eventType != 'ticket' && event.eventId != eventMain?.eventId);
  // 
  function cellOnClick() {
    // 有効範囲(1以上)であれば処理
    if (day > 0) {
      DayCellProp.daylyEventSetter(events);
      DayCellProp.setSelectedDateCell(DayCellProp.cellIdx);
      // 詳細リストを先頭に自動スクロール
      if (DayCellProp.autoScrollDataList?.current) {
        DayCellProp.autoScrollDataList.current.scrollTop = 0;
      }
    }
  }
  var frame = DayCellProp.selectedDateCell === DayCellProp.cellIdx ? <div className='selected-frame' /> : <div />;

  //
  return (
    <div id='calendar-cell' className={className} onClick={cellOnClick}>
      {frame}
      <CalendarCellHeader day={day} gameEvent={eventMain} key={"cell-header"} isHoliday={DayCellProp.isHoliday} />
      <CalendarCellMainEvent gameMain={eventMain} />
      <CalendarCellEventText ticketEvents={eventTickets} />
      <CalendarCellEvents events={eventOther} />
    </div>
  );
}

// -カレンダーのマス：ヘッダー (日付などの表示箇所)
function CalendarCellHeader(CellHeaderProp: { day: number, gameEvent: ScheduleData | null, isHoliday: boolean }) {
  var dayText = CellHeaderProp.day > 0 ? CellHeaderProp.day : " ";
  var event = CellHeaderProp.gameEvent;

  // 試合の場合の開催地の確認
  var gaameType = <div>　　</div>;
  if (event && event.gameInfo) {
    gaameType = event.gameInfo.isHomegame ?
      <img id='calendar-cell' className='game-type home' src={ImgHome} />
      : <img id='calendar-cell' className='game-type away' src={ImgAway} />;
  }
  return (
    <div id='calendar-cell' className='header'>
      {CellHeaderProp.isHoliday ? (<div id='calendar-cell' className='day holiday'>{dayText}</div>)
        : (<div id='calendar-cell' className='day'>{dayText}</div>)}
      {gaameType}
    </div>
  );
}
// -カレンダーのマス：メインイベント (大きいアイコンの表示箇所)
function CalendarCellMainEvent(CellMainEventProp: { gameMain: ScheduleData | null }) {
  var text = '　';
  var iconPath = '';

  // 表示するデータがあるかの確認
  if (CellMainEventProp.gameMain) {
    var event = CellMainEventProp.gameMain;
    iconPath = event.eventIconPath;
    // 試合の場合は試合相手、誕生日の場合はイベント情報を表示
    if (event.eventType == 'game') {
      text = event.gameInfo?.opponentName ? 'vs ' + event.gameInfo?.opponentName : '　';
    } else {
      text = event.eventInfo;
    }
  }

  // JSONに記載されていた物が空文字だった場合のスペース保持
  if (text.length === 0) text = '　';

  // パスが無い(空文字列)の場合には空白用のレイアウトを配置
  var iconImg = iconPath.length > 0 ? <img id='event-main' className="calendar-cell-icon" src={iconPath} /> : <div />;

  return (
    <div id='calendar-cell' className="event-main">
      <div id='event-main' className="icon-base">
        {iconImg}
      </div>
      <div id='event-main' className="name-text">{text}</div>
    </div>
  );
}
// -カレンダーのマス：イベントテキスト (イベントの詳細テキスト表示箇所)
function CalendarCellEventText(CellEventTextProp: { ticketEvents: Array<ScheduleData> }) {
  const events = CellEventTextProp.ticketEvents;
  // 
  var texs = ['　', '　'];
  var colors = ['#FFFFFF', '#FFFFFF'];//"いろはにほへとちりぬるをわかよたれそつねならむうゐのおくやまけふこえてあさきゆめみしゑひもせすんすんいろはにほへとちりぬるをわか";
  events.forEach((event, index) => {
    texs[index] = event.eventInfo;
    colors[index] = event.ticketBgColor.length === 7 ? event.ticketBgColor : '#FFFFFF';
  });
  var styles = [{ backgroundColor: colors[0] }, { backgroundColor: colors[1] }];

  return (
    <div id='calendar-cell' className="text-base">
      <div id='calendar-cell' className="event-text" style={styles[0]}>{texs[0]}</div>
      <div id='calendar-cell' className="event-text" style={styles[1]}>{texs[1]}</div>
    </div>
  );
}

// -カレンダーのマス：イベント一覧 (イベントのアイコンを表示する箇所)
function CalendarCellEvents(CellEventTextProp: { events: Array<ScheduleData> }) {
  var events = CellEventTextProp.events;
  var cellArray: Array<JSX.Element> = []
  // 表示数は最大4件なのでそれ以降は切り捨て
  for (var event of events) {
    var idx = cellArray.length;
    if (idx >= 4) break;
    var layout = <img id='event-line' className="icon" src={event.eventIconPath} key={'icon' + idx} />;
    cellArray.push(layout);
  }
  // 
  return (
    <div id='calendar-cell' className="event-line">
      {cellArray}
    </div>
  );
}

// -指定日付が国民の休日に該当するか
function isJpHoliday(targetDate: Date): boolean {
  return true
}
