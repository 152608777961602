/*=| スケジュールページの本体 |=*/
import { useEffect, useRef, useState } from 'react';
//
import { getCalendarData, getJpHolidays } from '../../domain/usecase/usecaseCalendar';
import { MonthlyScheduleDatas, ScheduleData } from '../../domain/models/calendarData';
// 
import { Calendar, CalendaraHeader } from './calendar';
import { CalendarDetail } from './calendarDetail';
import './styles/schedule.css';
// イメージの取り込み
import imagEmblem from '../../resources/emblem.png';
import imagTitle from '../../resources/title.png';

// ==
// 
export function Schedule() {
  // 
  const [targetDate, setTargetDate] = useState<Date>(new Date(new Date().toDateString()));// 選択月
  const [schedules, setSchedules] = useState<Array<MonthlyScheduleDatas>>([]);// 年月ごとに分けられたイベントスケジュール
  const [daylySchedules, setDaylySchedules] = useState<Array<ScheduleData>>([]);// 詳細に出す一日分のイベントデータ
  const [jpHolidays, setJpHolidays] = useState<Date[]>([]) // 日本の祝日
  //
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const autoScrollDataList = useRef<HTMLDivElement>(null)
  //
  useEffect(() => {
    // ダイアログ初期化
    setShowDialog(false)
    getCalendarData().then((models) => {
      //  Nullが返ってくる = サーバーエラー 
      if (models) {
        setSchedules(models);
        let allSchedules = models.find(schedule => {
          return (targetDate.getFullYear() === schedule.year) && (targetDate.getMonth() + 1 === schedule.month);
        }) || { year: targetDate.getFullYear(), month: targetDate.getMonth(), schedules: [] };
        let events = allSchedules.schedules.find(events => {
          return events.day === targetDate.getDate()
        })?.events || [];
        setDaylySchedules(events)
      } else { setShowDialog(true) }
    });
    getJpHolidays().then((holidays) => {
      setJpHolidays(holidays)
    })
  }, []);
  // 
  return (
    <div className='grid-area'>
      <div className='header-area'>
        <div id='schedule' className='body'>
          <SelctMonthArea targetDate={targetDate} setTargetDate={setTargetDate} setDaylySchedules={setDaylySchedules} key={"selector"} />
          <div className='dayofweek'>
            <CalendaraHeader key={"calendara-header"} />
          </div>
        </div>
      </div>
      <div className='calendar-area'>
        <div id='schedule' className='body'>
          <Calendar scheludes={schedules} targetDate={targetDate} setDaylySchedules={setDaylySchedules}
            key={"calendar"} autoScrollDataList={autoScrollDataList} holidays={jpHolidays} />
        </div>
      </div>
      <div className='boundary-line'></div>
      <div className='detail-area'>
        <CalendarDetail scheduleDataList={daylySchedules} key={"detail"} autoScrollDataList={autoScrollDataList} />
      </div>
      <ErrorDialog isShow={showDialog} />
    </div>
  );
}

function ErrorDialog(dialogProp: { isShow: boolean }) {
  return dialogProp.isShow ? (
    <>
      <div id='dialog' className='bg-cover'></div>
      <div id='dialog' className='server-error'>
        <div id='dialog' className='body' >
          <h4>カレンダー情報の取得に失敗しました。</h4>
        </div>
      </div>
    </>
  ) : (<></>);
}

// -月の選択エリア
function SelctMonthArea(SelectorProp: { targetDate: Date, setTargetDate: any, setDaylySchedules: any }) {
  var date = SelectorProp.targetDate;

  useEffect(() => {
    // 年を跨いで表示できないようにする (スケジュールJSONの関係上、表示時点での年のみ表示)
    changeButtonState('month-button prev', date.getMonth() != 0);
    changeButtonState('month-button next', date.getMonth() != 11);
  }, []);
  // 
  function changeMonth(selectNext: boolean) {
    var showDate = new Date(date.getFullYear(), date.getMonth());//その月の1日に設定
    // 月の変更 
    if (selectNext) { showDate.setMonth(showDate.getMonth() + 1); }
    else { showDate.setMonth(showDate.getMonth() - 1); }
    // 月変更のボタンの活性・非活性化対応
    changeButtonState('month-button prev', showDate.getMonth() != 0);
    changeButtonState('month-button next', showDate.getMonth() != 11);
    //
    SelectorProp.setTargetDate(showDate);
    SelectorProp.setDaylySchedules([]); //切り替えた際に残っている詳細を破棄
  }

  // 
  return (
    <div id='selector' className='body'>
      <input className='month-button prev' type='button' onClick={() => { changeMonth(false) }} value={'◀'} />
      <div id='selector' className='title'>{`${date.getFullYear()}.${date.getMonth() + 1}`}</div>
      <input className='month-button next' type='button' onClick={() => { changeMonth(true) }} value={'▶'} />
    </div>
  );
}
// -チーム名の載ったラベル部分
function TeamLabel() {
  return (
    <div id='team-label' className='body'>
      <img src={imagEmblem} className='icon' />
      <img src={imagTitle} className='text' />
    </div>
  );
}
// -ボタンの活性化状態変更
function changeButtonState(name: string, isActivate: boolean) {
  var btn = document.getElementsByClassName(name)[0];
  btn.setAttribute('disabled', 'true');
  if (isActivate) { btn.removeAttribute("disabled"); }
  else { btn.setAttribute("disabled", 'true'); }
}
