/* =|Data層で使用する固定値|= */

//接続先環境 [0:Dev, 1:Stg ,2: Prod]
const environment: number = 1;
//--
//= 接続先の情報取得 =//
const Endpoints = {
  DEV: "https://dev-sanga-calender-map-app-local.s3.ap-northeast-1.amazonaws.com/calender/football_calendar.json",
  STG: "https://app.footballteams.stg-sanga-kddi-fun-app.com/calendar",
  PRD: "prodacts"
} as const
type Endpoints = typeof Endpoints[keyof typeof Endpoints];

export function fetchURI() {
  switch (environment) {
    case 0: return Endpoints.DEV; // 開発環境
    case 1: return Endpoints.STG; // 検証環境
    case 2: return Endpoints.PRD; // 本番環境
    default: return Endpoints.DEV; // 変な値であれば検証環境を投げる
  }
}

//日本の祝日
export const JpHolidays = (() => [
  '2024/1/1',
  '2024/1/8',
  '2024/2/11',
  '2024/2/12',
  '2024/2/23',
  '2024/3/20',
  '2024/4/29',
  '2024/5/3',
  '2024/5/4',
  '2024/5/5',
  '2024/5/6',
  '2024/7/15',
  '2024/8/11',
  '2024/8/12',
  '2024/9/16',
  '2024/9/22',
  '2024/9/23',
  '2024/10/14',
  '2024/11/3',
  '2024/11/4',
  '2024/11/23',
  '2025/1/1',
  '2025/1/13',
  '2025/2/11',
  '2025/2/23',
  '2025/2/24',
  '2025/3/20',
  '2025/4/29',
  '2025/5/3',
  '2025/5/4',
  '2025/5/5',
  '2025/5/6',
  '2025/7/21',
  '2025/8/11',
  '2025/9/15',
  '2025/9/23',
  '2025/10/13',
  '2025/11/3',
  '2025/11/23',
  '2025/11/24'
])