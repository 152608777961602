/*=| データモデルで取り扱う固定値 |=*/

// 試合のタイプ(ホームorアウェー)
const GameType = {
  Home:"home", // ホームゲーム
  Away:"away", // アウェーゲーム
  NoGame:""    // 試合無し
} as const;
// イベントタイプ
const EventType ={
  Game:     "game",           // 試合
  BirthDay: "birthday",       // 選手誕生日
  Ticket:   "ticket",         // チケット販売
  Practice: "public_practice",// 公開練習
  Event:    "event",          // その他イベント
  Sale:     "sale"            // 販売 (物販？)
} as const;
//--
type GameType = typeof GameType[keyof typeof GameType];
type EventType = typeof EventType[keyof typeof EventType];

//==
//試合のタイプがホームゲームかの確認
export const isHomegame=(gameType:String)=> gameType === GameType.Home;
//イベントタイプが試合か
export const isGameEvent=(eventType:String)=> eventType === EventType.Game;
