/*=| 開催イベントの詳細部分 |=*/
import { ScheduleData, GameInfo } from '../../domain/models/calendarData'
//
import './styles/calendarDetail.css'
import { isGameEvent } from '../../domain/models/modelValues';
// イメージ確認用
import imagEmblem from '../../resources/emblem.png';

// ==
export function CalendarDetail(CalenderDetailProp: { scheduleDataList: Array<ScheduleData>, autoScrollDataList: any }) {
  // イベントデータを並び替える
  const sortedScheduleEventDataList = CalenderDetailProp.scheduleDataList.slice().sort(sortByEvent);
  // ダミーデータ
  // const sortedScheduleEventDataList = testScheduleDataList.slice().sort(sortByEvent);

  // 当日のイベントがない場合
  if (sortedScheduleEventDataList.length === 0) {
    return <div id='event-detail' className='body'>
      <NoEvents />
    </div>
  }

  return (
    <div id='event-detail' className='body' ref={CalenderDetailProp.autoScrollDataList}>
      {sortedScheduleEventDataList.map((data, index) => (
        <EventItem key={index} {...data} />
      ))}
    </div>
  );
}


// イベントの詳細表示
const EventItem = (scheduleData: ScheduleData) => {

  // 試合イベントの場合
  if (isGameEvent(scheduleData.eventType)) {
    return (
      <div id='event-item' className='body'>
        <div id='event-item' className='icon-area'><img id='event-item' className='icon' src={scheduleData.eventIconPath} alt="icon" /></div>
        <div id='event-item' className='vs'>vs</div>
        <div id='event-item' className='opponent'>{scheduleData.gameInfo?.opponentName}</div>
        <div id='event-item' className='time'>{scheduleData.gameInfo?.startTime}</div>
        {// イベントURLがあるならボタンを表示
          scheduleData.eventUrl && <a href={scheduleData.eventUrl}>
            <input className='link-button' type="button" value={'>'} />
          </a>}

      </div>
    )
  }

  return (
    <div id='event-item' className='body'>
      <div id='event-item' className='icon-area'><img id='event-item' className='icon' src={scheduleData.eventIconPath} alt="icon" /></div>
      <div id='event-item' className='info'>{scheduleData.eventInfo}</div>
      {// イベントURLがあるならボタンを表示
        scheduleData.eventUrl && <a href={scheduleData.eventUrl}>
          <input className='link-button' type="button" value={'>'} />
        </a>}

    </div>
  )
}

// 当日のイベントがない場合に表示
const NoEvents = () => {
  return (
    <div id='no-events'>登録されている予定はありません</div>
  )
}

// イベントのソート
const sortByEvent = (a: ScheduleData, b: ScheduleData) => a.eventSortKey - b.eventSortKey;